import * as React from "react"
import Layout from "../components/layout";
import {Link} from "gatsby"
import * as Container from "../components/container/container";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.Part background="white headline">
                    <Container.Row>
                        <div className="col-12">
                            <h1>Erklärung zur Barrierefreiheit</h1>
                        </div>
                    </Container.Row>
                </Container.Part>
                <Container.Part>
                    <Container.Row>
                        <div className="col-12">
                            <p>Der Bayerische Landtag ist bemüht, das Portal www.bayern.landtag.de im Einklang mit der
                                Bayerischen E-Government-Verordnung (BayEGovV) barrierefrei zugänglich zu machen und
                                allen Bürgerinnen und Bürgern einen Zugang zu den Angeboten zu verschaffen.</p>

                            <h2>Feedback und Kontaktangaben</h2>
                            <p>Aktuell bietet die Website ein zum großen Teil barrierearmes Erlebnis an und wir sind
                                auch weiterhin bemüht, dieses zu verbessern. Sollten Ihnen Barrieren begegnen, so bitten
                                wir Sie, uns diese per E-Mail an <a
                                    href="mailto:webmaster@bayern.landtag.de">webmaster@bayern.landtag.de</a> zu melden.
                            </p>

                            <h2>Erstellung dieser Erklärung zur Barrierefreiheit</h2>
                            <p>Das Portal des Bayerischen Landtages wurde in einem Selbsttest auf Grundlage der
                                Barrierefreie-Informationstechnik-Verordnung 2.0 sowie der Bayerischen
                                E-Government-Verordnung (BayEGovV) und unter Berücksichtigung der Anforderungen der Web
                                Content Accessibility Guidelines (WCAG) 2.1 geprüft. Die Erklärung zur Barrierefreiheit
                                wurde am 30. Juli 2021 erstellt.
                            </p>

                            <h2>Nicht barrierefreie Inhalte</h2>
                            <h3>PDF-Dateien</h3>
                            <p>Aktuell sind nicht alle PDF-Dateien in einem barrierefreien Format verfügbar. Wir sind
                                bemüht, in Zukunft ausschließlich zugängliche Dokumente anzubieten und alte Dateien zu
                                erneuern.
                            </p>

                            <h3>Audio- und Videodateien</h3>
                            <p>Nicht alle Videos werden mit passenden Untertiteln und/oder einer Audiodeskription
                                angeboten. Nicht alle Audiodateien verfügen über eine Textalternative.
                            </p>

                            <h3>Spezielle Unterseiten</h3>
                            Aktuell sind folgende Seiten nicht barrierefrei:
                            <ul>
                                <li><Link to="/interaktive-karte">Karte</Link></li>
                            </ul>
                            <br/>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
